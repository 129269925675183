.client::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-top: 45px solid #fff;
  border-right: 10px solid transparent;
  border-bottom: 0;
  border-left: 50px solid transparent;
  bottom: -24px;
  left: 20px;
}

.client {
  height: 40vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  text-align: center;
  background-color: #fff;
  position: relative;
  margin-top: 30px;
  margin-bottom: 165px;
  border-radius: 4px;
  padding: 50px 30px 30px; 
}
.client svg {
  background-color: #fff;
  box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
  color: #1dd3f8;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -30px;
}
.client__para {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.8;
  color: black;
}
.client__img img {
  width: 100%;
  height: 5rem;
  object-fit: contain;
  border-radius: 10px;
}
.client__img {
  position: absolute; 
  left: 30px;
  height: 60px;
  bottom: -90px;
 
  object-fit: contain;
}
.client__img h3 {
  margin-bottom: 0;
  font-size: 20px;
  color: #fff;
}
.des {
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: #1dd3f8;
}
.client__rating {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media screen and (max-width: 600)  {
  .client {
    width: 100%;
    margin-left: 0;
  }
}
