.footer {
  margin: 0px 4% 10px 4%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0px 20px 0px;
}
.footer__main {
  background-color: #000000;
}
.footer__description,
.footer__explore,
.footer__quicklinks,
.footer__getintouch {
  width: 23%;
  display: flex;
  height: 250px;
  /* justify-content: space-between; */
  flex-direction: column;
}
.description__logo {
  justify-content: flex-start;
}
.description__logo,
.footer__explore h3,
.footer__quicklinks h3,
.footer__getintouch h3 {
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.description__links {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.description__links a {
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.description__links span {
  color: #f7f7f7;
}

svg {
  margin-top: 5px;
}
.description__logo img {
  height: 5rem;
  width: 15rem;
  margin-left: -25px;
  object-fit: contain;
  margin-right: 8rem;
  filter: invert(100%);
}
.ul {
  padding-left: 0px;
}
.description__text {
  margin-bottom: 80px;
  margin-top: 24px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  text-align: left;
}
.description__links svg {
  border-radius: 50px;
  margin-right: 10px;
  cursor: pointer;
  padding: 8px;
}
.footer__explore h3,
.footer__quicklinks h3,
.footer__getintouch h3 {
  position: relative;
}
.footer__explore h3::after,
.footer__quicklinks h3::after,
.footer__getintouch h3::after {
  content: "";
  position: absolute;
  left: 35px;
  bottom: 0;
  height: 3px;
  background: #f1f2f4;
  width: 5px;
}
.footer__explore h3::before,
.footer__quicklinks h3::before,
.footer__getintouch h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 55px;
  height: 3px;
  background-color: #83f777;
}
.quick__heading {
  margin-left: 1rem;
}
.footer__explore {
  margin-left: 5rem;
}
.footer__getintouch {
  margin-right: 4rem;
}
.footer__explore ul,
.footer__quicklinks ul,
.footer__getintouch ul {
  list-style: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.footer__explore ul li,
.footer__quicklinks ul li,
.footer__getintouch ul li {
  margin-top: 10px;
  position: relative;
  color: #ffffff;
  cursor: pointer;
}

.footer__getintouch ul li {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.footer__getintouch ul li svg {
  border-radius: 50px;
  margin-right: 12px;
}
.footer__copyright {
  background-color: black;
  color: white;
  text-align: center;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__explore ul li span::before,
.footer__quicklinks ul li span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-100%);
  background-color: #045de9;
  border-radius: 50px;
  width: 10px;
  height: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
}
.footer__explore ul li span:hover:before,
.footer__quicklinks ul li span:hover:before {
  opacity: 1;
  visibility: visible;
}
.footer__quicklinks ul li:hover,
.footer__explore ul li:hover {
  transform: translateX(4%);
  color: #045de9;
  transition: all 0.6s ease;
}
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
  .footer__description,
  .footer__explore,
  .footer__quicklinks,
  .footer__getintouch {
    margin: 10px 2% 10px 2%;
    width: 96%;
    height: 210px;
  }
  .footer__quicklinks {
    margin-left: -1.5rem;
  }
  .footer__explore h3 {
    padding-top: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1200px) {
  .description__text {
    margin-bottom: 0px;
  }
  .footer {
    height: 24rem;
  }
  .footer__getintouch ul {
    font-size: 12px;
  }
  .description__links a {
    font-size: 12px;
  }
}
