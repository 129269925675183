.l2 {
  color: white;
}
.nav {
  padding: 0px 50px 10px 50px;
  display: flex;
  align-items: center;
  z-index: 10;
  color: white;
  width: 100%;
  position: fixed;
  color: #18181b;
  justify-content: space-between;
  background-color: transparent;
}
.navSecondary {
  padding: 0px 50px 0px 50px;
  display: flex;
  background: #072d34;
  align-items: center;
  z-index: 10;
  color: #18181b;
  width: 100%;
  position: fixed;
  color: #18181b;
  justify-content: space-between;
}
.title h1 {
  color: transparent;
  font-size: 50px;
  font-weight: bolder;
  text-align: center;
  background-clip: text;
}
a {
  color: black;
}
.alert-warning {
  background-color: #0096c7;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.anchor {
  text-decoration: none;
  color: white;
  width: 100%;
  cursor: pointer;
}
.anchor:hover {
  color: #016eea;
}
.anchorActive {
  text-decoration: none;
  color: #18181b;
  width: 100%;
  cursor: pointer;
}
.anchorActive:hover {
  color: #18181b;
}
.banner__left {
  width: 50%;
}
.banner__right {
  width: 0%;
}
.banner {
  font-weight: bold;
  background-color: #48cae4;
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 30px;
}
.register {
  cursor: pointer;
  width: 180px;
  background-color: #023e8a;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  border: none;
  color: white;
  border-radius: 10px;
  letter-spacing: 1px;
}
svg {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
  font-size: 40px;
  border: none;
  background: #eee;
  /* padding: 10px; */
  border-radius: 0;
}
.menu-icon {
  display: none;
  font-size: 20px;
}
.Dropdown-arrow,
.is-open {
  right: -2px;
  border-color: blue;
  border-color: blue transparent transparent;
}
.logo {
  height: 60px;
}
.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-left: 7px;
  filter: invert(100%);
}
.menu-list {
  list-style-type: none;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-list li a,
select {
  text-transform: capitalize;
  position: relative;
  border: none;
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
  padding: 5px 10px;
  margin-left: 20px;
  color: white;
}
.anchor::after {
  content: "";
  height: 2px;
  background: #016eea;
  position: absolute;
  bottom: 0px;
  opacity: 0;
  transform-origin: left center;
  transform: scaleX(0);
  transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  left: 10px;
  right: 0;
}
.anchorActive::after {
  content: "";
  height: 2px;
  background: #016eea;
  position: absolute;
  bottom: 0px;
  opacity: 0;
  transform-origin: left center;
  transform: scaleX(0);
  transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  left: 10px;
  right: 0;
}
.menu-list li a:hover::after,
.Dropdown-control:hover::after {
  opacity: 1;
  transform: scaleX(0.8);
}

.register {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.register::after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 5px;
  right: -20px;
  transition: 0.5s;
}
.banner__btn svg {
  background-color: transparent;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
}

.register:hover {
  padding-right: 24px;
  padding-left: 8px;
}
.register span {
  color: white;
}

.register:hover:after {
  opacity: 1;
  right: 10px;
}
.btns {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.btn__hire {
  box-shadow: 0 2px 4px 0 rgb(136 144 195 / 50%),
    0 5px 15px 0 rgb(37 44 97 / 21%);
  border-radius: 3px;
  margin-bottom: 21px;
  margin-right: 30px;
  border: 1px solid #29bb89;
  box-shadow: 1px 2px 26px -3px rgba(0, 0, 0, 0.49);
  color: #fff;
  font-weight: bold;
  background-color: #29bb89;
  font-size: 15px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.6s ease-in-out;
}

.btn__applyDev {
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 0px;
  color: #18181b;
  font-weight: bold;
  font-size: 15px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.btn__applyDev:hover {
  margin-top: -3px;
}

.li_btn {
  display: none;
}
@media screen and (max-width: 1128px) {
  .logo {
    font-size: 20px;
  }
  .btns {
    display: none;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1128px) {
  .menu-list li a:hover::after,
  .Dropdown-control:hover::after {
    opacity: 1;
    transform: scaleX(0.8);
    background-color: #023e8a;
  }
  .navSecondary {
    padding: 7px 220px 10px 0px;
    background-color: #000000;
  }
  .li_btn {
    display: block;
  }
  .btn__hire {
    padding: 5px;
    color: white;
  }
  .nav {
    padding: 7px 20px 10px 0px;
    background-color: transparent;
    backdrop-filter: blur(300px);
  }
  .menu-icon {
    display: block;
  }
  .menu-list li a {
    color: black;
  }
  .menu-list {
    background-color: white;
    flex-direction: column;
    width: 100%;
    margin: auto;
    position: absolute;
    padding: 20px;
    right: 0;
    top: 75px;
    display: flex;
    justify-content: stretch;
    height: 92vh;
    transition: all 0.5s ease-in-out;
  }

  .menu-list li {
    margin-bottom: 30px;
    color: black;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
    width: 250px;
    color: white;
    padding: 0;
  }

  .menu-list li:nth-child(4) {
    color: white;
    width: 210px;
    padding: 0;
  }
  .menu-list li a,
  .menu-list li:last-child a {
    display: block;
    font-size: 22px;
    margin: auto;
  }
  .menu-list li {
    opacity: 0;
  }
  .menu-list.active li {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 0.6s ease-in 0s;
  }
  .menu-list.active li:nth-child(1) {
    transition-delay: 0.4s;
  }
  .menu-list.active li:nth-child(2) {
    transition-delay: 0.5s;
  }
  .menu-list.active li:nth-child(3) {
    transition-delay: 0.6s;
  }
  .menu-list.active li:nth-child(4) {
    transition-delay: 0.7s;
    color: black;
  }
  .li_btn {
    width: 70%;
    padding: 2px;
  }

  .menu-list.active li:nth-child(5) {
    transition-delay: 0.8s;
    color: white;
  }
  .menu-list.active li:nth-child(6) {
    transition-delay: 0.9s;
    color: black;
  }
  .menu-list.close {
    top: -2000%;
    transition: all 0.5s ease-in-out;
  }
  .menu-list li a::after {
    left: 120px;
    right: 100px;
  }
  .alert-warning {
    font-size: 15px;
  }
  .register {
    letter-spacing: normal;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .alert-warning {
    flex-direction: column;
  }
  .banner__left,
  .banner__right {
    width: 100%;
  }
  .menu-icon svg {
    /* color: white; */
    background-color: transparent;
    font-size: 27px;
  }
}
@media (max-width: 1470px) and (min-width: 1128px) {
  .btns {
    margin-left: 0px;
    width: 10rem;
  }
  .logo {
    margin-left: -2rem;
  }
  .menu-list {
    margin-left: -3rem;
  }
  .btn__hire {
    margin-right: 20px;
  }
  .btn__applyDev {
    margin-right: -2rem;
  }
}
@media screen and (min-width: 800px) and (max-width: 1020) {
  .navSecondary {
    padding: 7px 20px 10px 0px;
  }
}
