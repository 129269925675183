@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;700&family=Open+Sans:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* #E9F1FF; */
/* #f5f8fd; */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Dosis", sans-serif;
}

p,
span,
ul,
button,
li {
  font-family: "Open Sans";
}
.App {
  overflow: hidden;
}
