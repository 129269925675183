.company-section{
   
    /* border: 1px solid black; */
    margin: auto;
    margin-bottom: 5rem;
    padding: 20px auto;
    margin-top: 40px;
}

.content{
    background: transparent url("https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y29tcGFueXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60") 100% 0 no-repeat;
    background-position: 50% ;
    background-size: cover;
    /* Set a specific height */
    min-height: 500px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-top: 70px; */
  }
  .titleee{
    position: relative;
    color: #FFFFFF;
    padding-top: 50px;
    padding-bottom: 70px;
    text-align: center;
  }
  .dtable{
    width: 100%;
    height: 100%;
    display: table;
  }
  .dtable-cells{
  vertical-align: middle;
  display: table-cell;
  } 
  .container{
    position: relative;
  }
  .heading{
    font-size: 50px;
  }
  .titleee::before{
    content: "";
    opacity: 0.8;
    background-color: #18181b;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
.company-section-header{
    padding: 20px;
    text-align: center;
    font-size: 3.4em;
    font-weight: bold;
    color: white;
}
.company__Client{
    width: 150px;
    height: 80px;
    object-fit: contain;
}
.company-section-cards{
    /* border: 1px solid black; */
    padding: 0px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.company-card{
    height: 80px;
    width: 200px;
    margin-left: 7rem;
    margin-right: 7rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    place-items: center;
    font-size: 1.5em;
    border-radius: 10px;
    margin-bottom: 30px;
}


@media (max-width:600px){
    .company-section-header{
        font-size: 2.5em;
    }
    
    .company-card{
        height: 60px;
        width: 130px;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 20px;
    }
    .company__Client{
        height: 80px;
        width: 120px;
        object-fit: contain;
    }
}
@media (max-width:829px) and (min-width:601px){
  .titleee::before{
    height: 107%;
  }
  .company-section-header{
    font-size: 2.5em;
}

.company-card{
    height: 60px;
    width: 130px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 20px;
}
.company__Client{
    height: 80px;
    width: 120px;
    object-fit: contain;
}
}