.submit__heading{
    font-size: 30px;
    font-weight: 600;
}
.submit__subheading{
    font-size: 20px;
}
.backtohome__btn{
    margin-top: 10px;
    width: 160px;
    position: absolute;
    font-weight: 500;
    background-color: #2D46B9;
    color: white;
    font-size: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    border-radius: 5px;
    position: relative;
    transition: all .6s ease-in-out;
}
.backtohome__btn:hover{
    transform: translateY(-5px);
}
.submit__btn{
    margin-top: 10px;
    width: 100px;
    position: absolute;
    font-weight: 500;
    background-color: #2D46B9;
    color: white;
    font-size: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    border-radius: 5px;
    position: relative;
    transition: all .6s ease-in-out;
    opacity: 0.8;
}
.submit__btn:hover{
    opacity: 1;
}