.header-parent-container {
  margin: 13% 5% 10% 5%;
  width: 90%;
  display: flex;
  padding-bottom: 5%;
  text-align: left;
}

.header-left-container {
  width: 60%;
  text-align: left;
  margin-right: 150px;
}
.header-left-heading{
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
}
.header-left-desc{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  font-family: Open Sans;
  margin-bottom: 40px;

}

.header-main-container {
  margin-top: 100px;
  max-height: fit-content;
}

h1 {
  font-family: Dosis;
  color: black;
  
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
}

p {
  font-family: Open Sans;
  margin-bottom: 20px;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
}

.header-right-container {
  width: 40%;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media screen and (min-width: 601px) and (max-width:769px){
  .header-parent-container {
    flex-direction: column;
    margin-top: 12%;
    text-align: left;
  }
  .header-left-container {
    width: 100%;
    margin-bottom:4rem;
  }
  .header-left-heading{
    font-size: 30px;
    margin-top: 15px;
  }
  .header-left-desc{
    line-height: 25px;
  }

  .header-right-container {
    width: 100%;
    height: 45vh;
  }

  .header-right-container img {
    margin: auto;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 600px) {
  .header-parent-container {
    flex-direction: column;
    margin-top: 22%;
    text-align: left;
  }

  .header-left-container {
    width: 100%;
    margin: auto;
  }
  .header-left-heading{
    font-size: 30px;
    margin-top: 15px;
  }
  .header-left-desc{
    line-height: 25px;
  }

  .header-right-container {
    width: 100%;
    height: 45vh;
  }

  .header-right-container img {
    margin: auto;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    letter-spacing: 1px;
  }
}
