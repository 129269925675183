.blogCard {
  height: 200px;
  /* margin-bottom: 100px; */
  background-color: aliceblue;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}
.blogTitle {
  color: rgb(0, 0, 0);
  font-family: Dosis;
  font-weight: 700;
  font-size: 3rem;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.author {
  font-size: 20px;
}

.blog_detail {
  width: 70%;
  padding: 20px;
  transition: all 0.5s;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
.blog_arrow {
  color: white;
  width: 30%;
  background: #292929;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.blog_arrow svg {
  filter: invert(89%);
}
.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px 15px;
  font-size: 15px !important;
}
.tags h5 {
  font-weight: 600;
}

@media screen and (max-width: 700px) {
  .blogCard {
    flex-direction: column;
    height: 16rem;
  }
  .blog_detail {
    width: 100%;
    height: 100%;
    transition: hide;
    font-size: 40px;
    gap: 10px;
    align-items: start;
  }
  .blog_arrow {
    display: none;
  }
  .blogTitle {
    font-size: 1.8rem;
  }
  .tags {
    justify-content: start;
    font-size: 10px !important;
  }
}

@media screen and (min-width: 880px) {
  .blogCard:hover .blog_detail {
    width: 90%;
  }
}
