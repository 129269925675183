.header-section{
    display: flex;
    margin: 10% 5% 5rem 5%;
    width: 90%;
}
.headerSection__left{
    width: 50%;
}
.headerLeft__heading h1{
    
    font-family: Dosis;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
}

.headerLeft__para{
    margin-bottom: 3rem;
}
.headerSection__right{
    width: 50%;
}
.headerRightImg{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
@media screen and (max-width:480px) {
    .header-section{
        flex-direction: column;
        margin-top: 20%;
    }
    .headerSection__left{
        width: 100%;
        margin-bottom: 2rem;
    }
    .headerSection__right{
        width: 100%;
    }
    .headerLeft__heading h1{
        font-size: 30px;
        margin-bottom: 20px;
    }
}