.vet__cover {
  background-color: #072d34;
  padding: 80px;
}
.vet__section1 {
  padding: 8% 0% 10% 0%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 90%;
}
.vet1__left {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.vet1__left p {
  color: #ffffff;
  font-size: 16px;
  text-align: justify;
}
.vet2__6heading {
  color: #29bb89;
}

.vet1__span {
  color: #29bb89;
  margin-top: 50px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 30px;
}

.vet1__heading {
  font-family: Dosis;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  color: white;
}
.vet2__1 h1 {
  color: #29bb89;
}
.vet2__2 h1 {
  color: #29bb89;
}
.vet2__3 h1 {
  color: #29bb89;
}
.vet2__4 h1 {
  color: #29bb89;
}
.vet2__5 h1 {
  color: #29bb89;
}
.vet1__p {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: white;
}
.vet1__btn {
  margin-top: 30px;
}
.vet1__right {
  width: 45%;
}
.vet1__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.vet2__heading {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 50px;
  font-size: 64px;
  color: white;
}
.vet2 {
  display: flex;
  flex-direction: column;
  margin: 50px 5% 0;
}

.vet2 p {
  color: white;
}

.vet2__1,
.vet2__2,
.vet2__3,
.vet2__4,
.vet2__5,
.vet2__6 {
  display: flex;
  justify-content: space-evenly;
  margin: 25px auto;
}
.vet2__1left,
.vet2__1right,
.vet2__2left,
.vet2__2right,
.vet2__3left,
.vet2__3right,
.vet2__4left,
.vet2__4right,
.vet2__5left,
.vet2__5right,
.vet2__6left,
.vet2__6right {
  width: 45%;
  display: flex;
  justify-content: center;
}
.vet2__1right,
.vet2__2left,
.vet2__3right,
.vet2__4left,
.vet2__5right,
.vet2__6left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
}

.vet2__img {
  width: 80%;
  height: 99%;
  object-fit: contain;
}
.vet2__img2 {
  width: 92%;
  height: 94%;
}
.vet2__img3 {
  width: 100%;
  height: 100%;
}
.vet2__img4 {
  width: 80%;
  height: 100%;
}
.vet2__img5 {
  width: 100%;
  height: 100%;
}

.vet2__img6 {
  width: 100%;
  height: 100%;
}
.hero_contact1 {
  color: white;
  display: flex;
  align-items: center;
  transition: 1s;
  margin-left: 5rem;
  padding-bottom: 60px;
  margin-top: 2rem;
}
.hero_contact1 p {
  color: #32e5a6;
  margin: 0;
  padding-right: 5px;
  font-size: 1.3rem;
  transition: 0.5s;
  font-weight: 700;
}
.hero_contact1 svg {
  color: #32e5a6;
  background: transparent;
}
.hero_contact1 p:hover {
  color: #32e5a6;
  padding-left: 50px;
  padding-right: 5px;
}
@media screen and (max-width: 768px) {
  .hero_contact1 {
    justify-content: center;
    margin-left: 0;
  }
  .hero_contact1 p {
    font-size: 1.2rem;
  }
  .hero_contact1 svg {
    height: 30px;
    width: 30px;
  }
  .vet__cover {
    padding: 10px;
    padding-top: 80px;
  }
  .vet__section1 {
    flex-direction: column;
    /* margin-top: 10%; */
    justify-content: space-evenly;
  }
  .vet1__left {
    width: 100%;
    margin-bottom: 50px;
  }
  .vet1__left p {
    text-align: center;
  }
  .vet1__p {
    text-align: center;
  }

  .vet1__span {
    text-align: center;
  }

  .vet1__right {
    width: 100%;
  }
  .vet2__1,
  .vet2__2,
  .vet2__3,
  .vet2__4,
  .vet2__5,
  .vet2__6 {
    flex-direction: column;
    padding-bottom: 80px;
  }
  .vet2__1left,
  .vet2__1right,
  .vet2__2left,
  .vet2__2right,
  .vet2__3left,
  .vet2__3right,
  .vet2__4left,
  .vet2__4right,
  .vet2__5left,
  .vet2__5right,
  .vet2__6left,
  .vet2__6right {
    width: 100%;
    text-align: center;
  }
  .vet2__2,
  .vet2__4,
  .vet2__6 {
    flex-direction: column-reverse;
  }
  .vet2__heading {
    font-size: 40px;
  }
  .vet1__heading {
    font-size: 40px;
    text-align: center;
  }
  .vet1__btn {
    margin: 15px auto;
  }
  .vet__grad {
    margin: 0px 20px;
  }
}
@media screen and (min-width: 1200px) {
  .hero_contact1 {
    margin-left: 6rem;
  }
}
