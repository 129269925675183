.back__btn{
    margin-top: 10px;
    width: 100px;
    position: absolute;
    left: 0;
    font-weight: 500;
    color: #2D46B9;
    background-color: white;
    font-size: 18px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    border-radius: 5px;
    transition: all .6s ease-in-out;
}
.back__btn::after{
    content: "<";
    position: absolute;
    left: 15px;
    opacity: 0;
    transition: 0.6s;
}
.back__btn:hover{
    padding-right: 8px;
    padding-left: 24px;
}
.drop{
    margin-left: -20px;
}
.back__btn:hover::after{
    opacity: 1;
    left: 20px;
}
label{
    margin-bottom: 10px;
}
.dropdown__label{

margin-bottom: 10px;
margin-left: 1rem;
}