.experience-section{
    background-color: white;
    margin: 1rem 5% 1rem 5%;
    border-radius: 15px;
   padding: 3rem;
}
.experience-section-header{
 
    text-align: center;
}
.experience-header-heading{
    
    font-size: 2.5em;
    font-weight: 700;
}
.experience-header-text{
   
    font-size: 1.3em;
    color: #63615a;
    font-weight: 500;
}
.experience-section-content{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */

    /* border: 1px solid black; */
}
.section-card{
    /* border: 1px solid black; */
    width: 410px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    text-align: center;
    /* margin: 0rem 4rem; */
    /* height: 200px; */
    
    padding: 30px;
}
.section-card-content{
    font-size: 20px;
    font-weight: 300;
}
.section-card-image{
    height: 70px;
    width: 50px;
    margin-right: 1rem;
}
.experience-section-button{
    margin: auto;
    width: fit-content;
    transform: translateY(-15px);
}

@media (max-width:600px){
    .experience-section{
        height: fit-content;
    }
    .experience-header-heading{
        font-size: 2em;
        font-weight: 700;
    }
    .experience-header-text{
        font-size: 1em;
        color: #63615a;
        font-weight: 500;
    }
    .experience-section-content{
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
    }
    .section-card{
        width: 250px;
        height: fit-content;
        margin-bottom: 0px;
        border-right:none;
    }
    .section-card-content{
        font-size: 1em;
        font-weight: bold;
    }
    .section-card-image{
        height: 60px;
        width: 40px;
    }
    .experience-section-button{
        padding: 0px;
        transform: translateY(0px);
    }
    .button-title{
        margin-left: 10px;
    }
}
@media (max-width:829px) and (min-width:601px){
    .section-card{
        width: 280px;
    }
}
@media (max-width:1470px) and (min-width:830px){
 .section-card{
     width: 400px;
 }
}