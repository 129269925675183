@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
.faqSection{
    margin: 10px 5% 10px 5%;
    padding-left: 0px;
    width: 90%;
}
.faqsubheading{
    font-size: 100px;
    opacity: .1;
    font-family: Monoton,cursive;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: -4rem;
}

.main__heading{
    background: #fff;
    margin-bottom: 10px;
    position: relative;
    list-style: none;
    border: 1px solid #f5f5f5;
}
.faqAnswer{
    padding-left: 10px;
    padding-top: 10px;
    border-top: 1px solid #f64c67;
  }
.acc__btn{
    cursor: pointer;
    border-bottom: none;
    color: #333;
    cursor: pointer;
    padding: 5px 20px 10px 10px;
    display: flex;
    margin: 0;
    text-decoration: none;
    -webkit-transition: .5s;
    transition: .5s;
    position: relative;
    font-size: 17px;
    font-weight: 600;
    background: #fff;
}

.faqHeading{
    text-align: center;
    font-weight: 500;
}
.faqToggleIcon{
    margin: auto;
    cursor: pointer;
    font-size: 1rem;
}
.faqQuestion{
    font-size: 20px;
    font-weight: 600;
    flex-grow: 1;
}
.panel {
    padding: 0 18px;
    background-color: white;
    overflow: hidden;
    /* float: right; */
    /* flex-direction: row-reverse; */
  }