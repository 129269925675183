.dev__card {
  position: relative;
  width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgb(0, 0, 0, 0.05),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  margin: 30px;
}
.dev__ul {
  list-style-image: url("./arrow.png");
}
.dev__ul li {
  margin-top: 10px;
  color: #072d34;
}
.dev__box {
  background: #19191a;
  box-shadow: 0 10px 20px rgba(155, 119, 119, 20%);
  display: flex;
  width: 350px;
  height: 300px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.black_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    33deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(5, 5, 5, 0.8323704481792717) 20%,
    rgba(0, 212, 255, 0) 100%
  );
}
.dev__content {
  text-align: center;
}
.dev__heading {
}
.dev__spa {
  /* background: #29bb89; */
  color: white;
  position: absolute;
  border-radius: 5px;
  padding: 5px 5px 15px 5px;
  width: 90%;
  bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
}

.dev__box:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 50%);
  transform: translateY(-5px);
}
.dev__img1 {
  height: 80%;
  width: 100%;
}
.dev__btn {
  padding: 7px;
  width: 55%;
  font-size: 20px;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border: none;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 10px;
  /* margin: auto; */
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.dev__btn::after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 5px;
  right: -20px;
  transition: 0.5s;
}
.dev__btn:hover {
  padding-right: 24px;
  padding-left: 8px;
  background-color: #29bb89;
  color: white;
}
.dev__btn:hover::after {
  opacity: 1;
  right: 10px;
}
.dev__sections {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  row-gap: 30px;
}
@media screen and (min-width: 1200px) {
  .dev__sections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
  }
}
@media screen and (min-width: 770px) and (max-width: 1200px) {
  .dev__box {
    width: 320px;
  }
}
@media screen and (min-width: 601px) and (max-width: 769px) {
  .dev__box {
    width: 320px;
  }
}
@media screen and (max-width: 600px) {
  .dev__card {
    margin: 20px 0px;
  }
  .dev__box {
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .dev__img1 {
    height: 100%;
    width: 100%;
  }
  .dev__box {
    height: 250px;
    width: 70%;
  }
  .dev__sections {
    display: flex;
  }
}
