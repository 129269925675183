.form1{
    background-color: #f5f8fd;
}
.step1{
    display: flex;
}
.form__banner{
    color: white;
    font-style: normal;
    font-weight: 700;
    margin-top: 2rem;
    font-size: 2rem;
}
.form__heading2{
    margin-bottom: 3rem;
}
.stepheading{
    margin-bottom: 40px;
    color: white;
}
.form__section{
    padding: 1rem 1rem;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 4rem;
}
.chip,.singleChip{
    white-space: normal;
    
}
.form__heading{
    text-align: center;

}
.form__logo{
    width: 20rem;
    height: 5rem;
    object-fit: contain;
}
.getyoustared{
    font-weight: 100;
    margin-top: 1rem;
    text-align: center;
}
.step1__right{
    background-image: url("./gradient.png");
    background-repeat:no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 30%;
}
.step1__left{
    width: 70%;
    /* height: 80vh; */
    display: flex;
    margin: 10px 5%;
    align-items: center;
    flex-direction: column;
}
.form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 580px;
}
::-webkit-inner-spin-button{
    display: none;
}

.navigation__btns{
    text-align: right;
    margin-top: 2rem;
    position: relative;
}
.form__box{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}
.input{
    border-radius: 5px;
    border: none;
    background: #eeeeee;
    padding: 13px;
    padding-left: 10px;
}
.input:focus{
    outline: none;
}
.next__btn{
    margin-top: 10px;
    width: 100px;
    position: absolute;
    font-weight: 500;
    background-color: #2D46B9;
    color: white;
    font-size: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    border-radius: 5px;
    position: relative;
    transition: all .6s ease-in-out;
}
label{
    font-size: 20px;
    font-weight: 400;
}
.next__btn::after{
    content: "»";
    transition: all .6s ease-in-out;
    position: absolute;
    top: 1  px;
    opacity: 0;
    right: 10px;
}
.next__btn:hover{
    padding-right: 24px;
    padding-left: 8px;
}
.next__btn:hover:after{
    opacity: 1;
    right: 15px;
}
@media screen and (min-width: 481px) and (max-width:768px){
    .step1{
        flex-direction: column;
        
    }
    .step1__left{
        width: 90%;
    }
    .step1__right{
        width: 100%;
        /* height: 20vh; */
        /* display: none; */
    }
    .form{
        max-width: 580px;
        width: 35rem;
        min-width: 0px;
    }
}
@media screen and (min-width: 768px) and (max-width:1200px){
.step1{
    flex-direction: column;
}
.step1__left{
    width: 90%;
    
}
.step1__right{
    width: 100%;
    height: 42rem;
}
.form__section{
    padding: 5rem 1rem;
}
}
@media screen and (max-width:500px){
    .step1{
        flex-direction: column;
        
    }
    .step1__left{
        width: 90%;
    }
    .step1__right{
        width: 100%;
        /* height: 20vh; */
        /* display: none; */
    }
    .form{
        max-width: 580px;
        width: 20rem;
        min-width: 0px;
    }
}