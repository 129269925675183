.grad {
  margin: 50px -10%;
  height: 190px;
  background: linear-gradient(
    135deg,
    hsla(229, 61%, 45%, 1) 0%,
    hsla(159, 64%, 45%, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn__getDeveloperGrad {
  border-radius: 5px;
  margin-bottom: 15px;
  margin-left: 35px;
  border: 1px solid #29bb89;
  color: #fff;
  font-weight: bold;
  background-color: #2d46b9;
  font-size: 25px;
  padding: 10px;
  cursor: pointer;
  position: relative;
}
.grad__heading {
  font-size: 45px;
  color: white;
}
@media screen and (max-width: 600px) {
  .grad {
    flex-direction: column;
    height: 24rem;
  }
  .btn__getDeveloperGrad {
    margin-right: 35px;
  }
  .grad__heading {
    text-align: center;
    margin: 15px 38px;
  }
  .grad__heading {
    font-size: 45px;
  }
}
@media screen and (min-width: 500px) and (max-width:768px){
  .grad__heading{
    font-size: 1.8rem;
  }
  .grad {
    flex-direction: column;
    height: 15rem;
  }
  .btn__getDeveloperGrad {
    margin-right: 35px;
  }
  .grad__heading {
    text-align: center;
    margin: 15px 38px;
  }
}
@media screen and (min-width: 768px) and (max-width:1200px){
  .grad__heading{
    font-size: 30px;
  }
}