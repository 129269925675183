.btn__getDeveloper {
    border-radius: 3px;
    margin-bottom: 15px;
    margin-right: 10px;
    border: 1px solid #29bb89;
    box-shadow: 1px 2px 26px -3px rgba(0, 0, 0, 0.49);
    color: #fff;
    font-weight: bold;
    background-color: #29bb89;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out;
    
  }
  
  .btn__getDeveloper:hover {
    background-color: #0e9767;
  }
  a{
    text-decoration: none;
  }