.image-section{
    /* border: 1px solid black; */
    
    margin: 4rem auto;
    /* color: white; */
}
.image-section-heading{
    /* border: 1px solid black; */
    padding: 20px;
    text-align: center;
    font-size: 3.4em;
    font-weight: bold;
}
.imgSectionSubHeading{
    font-size: 100px;
    opacity: .1;
    font-family: Monoton,cursive;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: -4rem;
}
.imgSectionHeading{
    font-weight: 600;
}
.image-section-card{
    /* border: 1px solid black; */
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;
}
.image-box{
    background-image: url('https://source.unsplash.com/random');
    width: 450px;
    height: 300px;
    border-radius: 10px;
    /* border: 1px solid black; */
    background-size: cover;
}
.content-box{
    /* border: 1px solid black; */
    padding: 0px;
}
.content-box-heading{
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 50px;
}
.content-box-list{
    font-size: 1.5em;
}
.box-list-item{
    margin-bottom: 20px;
    list-style-type: none;
}
.bi-check-circle-fill{
    margin-right: 15px;
    font-size: 1.5em;
    transform: translateY(30px);
}

@media (max-width : 900px){
    .imgSectionSubHeading{
        font-size: 4rem;
    }
    .image-section-heading{
        padding: 10px;
        font-size: 2.7em;
    }
    .image-section-card{
        flex-direction: column-reverse;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }
    .image-section-card:nth-child(2n){
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: 0px;
    }
    .image-box{
        width: 250px;
        height: 130px;
        margin-bottom: 10px;
    }
    .content-box-heading{
        font-size: 1.5em;
        margin-bottom: 10px;
        margin-left: 35px;
    }
    .content-box-list{
        font-size: .8em;
    }
    .box-list-item{
        margin-bottom: 10px;
    }
    .bi-check-circle-fill{
        margin-right: 10px;
        font-size: 1.5em;
    }
}