.how {
  background-color: #072d34;
}
li {
  color: white;
}
.how__mainHeading {
  letter-spacing: 1px;
  text-align: center;
  font-weight: 600;
  font-size: 64px;
  color: white;
  padding-top: 6%;
}
.how__heading {
  font-size: 40px;
  font-weight: 500;
  color: white;
}
.how__para {
  margin-bottom: 30px;
  color: white;
}
.how__hirebutton {
  margin-bottom: 30px;
}
.how1__right img,
.how2__left img,
.how3__right img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 55px;
}
.how1,
.how2,
.how3 {
  display: flex;
  padding: 5%;
  justify-content: center;
  width: 90%;
  justify-content: space-evenly;
  margin-left: 5%;
}

.how2 ul {
  list-style: none;
  padding-left: 0;
}

.how2 i {
  margin-right: 5px;
  font-size: 25px;
}

.how3 ul {
  list-style: none;
  padding-left: 0;
}

.how3 i {
  margin-right: 5px;
  font-size: 25px;
}

.how1 p,
.how2 p,
.how3 p {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.how1 ul,
.how2 ul,
.how3 ul {
  font-size: 16px;
  font-weight: 500;
  color: #5a5f65;
}

.how1__left,
.how1__right,
.how2__left,
.how2__right,
.how3__left,
.how3__right {
  width: 40%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .how1,
  .how2,
  .how3 {
    padding-bottom: 80px;
  }
  .how2 {
    flex-direction: column-reverse;
    width: 90%;
  }
  .how1__left,
  .how1__right,
  .how2__left,
  .how2__right,
  .how3__left,
  .how3__right {
    width: 100%;
  }
  .how1,
  .how3 {
    flex-direction: column;
    width: 90%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  .how__mainHeading {
    font-size: 38px;
    padding-top: 20%;
  }
  .how__heading {
    font-size: 30px;
  }
  .how1__left,
  .how2__right,
  .how3__left {
    text-align: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
