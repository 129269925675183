.getStarted__btn{
    margin-top: 10px;
    width: 150px;
    position: absolute;
    font-weight: 500;
    background-color: #2D46B9;
    color: white;
    font-size: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    border-radius: 5px;
    position: relative;
    transition: all .6s ease-in-out;
}
.chip{
    white-space: normal;
}
.getStarted__btn:after{
    content: "»";
    transition: all .6s ease-in-out;
    position: absolute;
    top: 1px;
    opacity: 0;
    right: 3px;
}

button:disabled{
    opacity: 0.7;
}
.noerr{
    display: none;
}
.err{
    display: block;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}
.getStarted__btn:hover{
    padding-right: 24px;
    padding-left: 8px;
}
.getStarted__btn:hover:after{
    opacity: 1;
    right: 10px;
}