.hero_back {
  background-image: url("./video11.gif");
  height: 100%;
  background-position: center;
  background-size: cover;
}
.hero_back2 {
  background-image: url("./placeholder.jpeg");
  height: 100%;
  background-position: center;
  background-size: cover;
}
.hero_backcontainer {
  backdrop-filter: blur(5px);
}
.hero {
  margin: 7% 5% 0% 5%;
  width: 90%;
  display: flex;
  padding-bottom: 4%;
  justify-content: center;
}
.home {
  background-color: #f5f8fd;
}
.hero2 {
  margin: 5% 5% 50px 5%;
  width: 90%;
  display: flex;
}
.hero__cover {
  /* background-color: #016EEA; */
  padding: 6%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.hero {
  text-align: center;
}
.hero__left {
  width: 70%;
  text-align: left;
  /* height: 60vh; */
}
.wave {
  width: 100vw;
  height: 85vh;
}
.hero2__left {
  width: 45%;
}

.hero__heading {
  font-family: Dosis;
  /* color: white; */
  color: white;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 54x;
  text-align: center;
}
.hero__desc {
  font-family: Open Sans;
  margin-bottom: 20px;
  /* color: white; */
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.7;
  text-align: center;
}
.buttons {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}
.btn__getDeveloper {
  border-radius: 3px;
  margin-bottom: 15px;
  margin-right: 10px;
  border: 1px solid #29bb89;
  box-shadow: 1px 2px 26px -3px rgba(0, 0, 0, 0.49);
  color: #fff;
  font-weight: bold;
  background-color: #29bb89;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.sec {
  background-color: #f5f8fd;
}
.btn__getDeveloper:hover {
  background-color: #0e9767;
}
.hero_contact {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.hero_contact p {
  color: #32e5a6;
  margin: 0;
  padding-right: 5px;
  font-size: 1.3rem;
  transition: 0.5s;
  font-weight: 700;
}
.hero_contact svg {
  color: #32e5a6;
  background: transparent;
}
.hero_contact p:hover {
  color: #32e5a6;
  padding-left: 50px;
  padding-right: 5px;
}

.btn__apply {
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 15px;
  /* color: #fff; */
  color: #2d46b9;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.btn__apply:hover {
  transform: translateY(-3px);
}

.hero2__right {
  width: 45%;
}
.hero2__right img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.hero__right {
  width: 52%;
  height: 439px;
}

.hero__right img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.heading {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 64px;
}

/* BOX STYLING */
.box {
  height: 160px;
  margin-right: 20px;
  width: 160px;
  border-radius: 10px;
  transition: all 0.6s ease;
  cursor: pointer;
  box-shadow: 1px 3px 26px 0px rgba(0, 0, 0, 25%);
}
.box:hover {
  background-color: #29bb89;
  transform: translateY(-10px);
  color: white;
}
.box__logo {
  height: 30%;
}
.box__logo img {
  margin-top: 5px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.description {
  height: 70%;
  margin-top: 9px;
}
.description p {
  text-align: center;
  font-size: 17px;
  font-weight: 700;
}

.hero2__left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
}
.boxes1,
.boxes2 {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-around;
}
/* Ingenious Devs */
.ings__heading {
  letter-spacing: 1px;
  font-family: Dosis;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  font-size: 64px;
  margin-bottom: 30px;
}
.igns {
  padding: 3rem 5% 50px 5%;
  background-color: #f5f8fd;
}
.igns h3 {
  margin: 30px 0;
}

.dev__sections {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  flex-wrap: wrap;
}
/* MEDIA QUERIES */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 766px) {
  .hero__left {
    width: 100%;
  }
  .hero_contact p {
    font-size: 1rem;
  }
  .hero_contact svg {
    width: 1.5rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .hero {
    margin-top: 12%;
    padding-bottom: 0px;
  }
  .hero__heading {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .buttons {
    width: 24rem;
  }
  .trust__heading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .hero__heading {
    font-size: 30px;
  }
  .heading {
    font-size: 40px;
  }
  .hero__right img {
    height: 100%;
  }

  .hero {
    padding-bottom: 0;
  }

  .companies__carousel {
    width: 100%;
    margin: 0px;
  }
  .logo img {
    width: 100%;
  }
  .buttons {
    flex-direction: column;
  }
  .hero2__left h1 {
    color: black;
  }

  .btn__apply {
    width: 81%;
    color: #016eea;
    margin: 15px auto;
    text-align: center;
    font-size: 18px;
  }
  .btn__getDeveloper {
    font-size: 13px;
    width: 250px;
    text-align: center;
    margin: auto;
    padding: 5px;
  }
  .ings__heading {
    font-size: 40px;
  }
  .hero__right {
    width: 100%;
    height: 27vh;
  }
  .hero2 {
    flex-direction: column;
  }
  .description p {
    font-size: 15px;
    letter-spacing: 1px;
  }
  .box {
    height: 150px;
  }
  .hero {
    flex-direction: column;
    margin-top: 19%;
    text-align: left;
    /* height: 90vh; */
  }
  .hero2__left {
    width: 100%;
  }
  .hero__heading {
    margin-bottom: 10px;
  }
  .hero__left {
    margin: auto;
  }
  .hero2__right {
    width: 100%;
  }
  .hero__desc {
    line-height: 20px;
    font-size: 15px;
  }
  .hero__right img {
    margin: auto;
  }
}
